<template>
  <div class="">
    <div>
      <el-carousel
        :autoplay="false"
        arrow="never"
        indicator-position="none"
        ref="cardShow"
        trigger="click"
        @change="changevideofalse"
      >
        <el-carousel-item
          v-for="(item, index) in picture"
          class="small"
          :key="item.id"
        >
          <div class="swiperwidth">
            <div>
              <el-image
                v-if="!videohidden"
                :src="item.path"
                fit="contain"
                class="imgsize"
                width="400px"
              ></el-image>
            </div>
   <div     
               @mouseleave="mouseleavevideo"
                @mouseenter="mouserentervideo">
                <video
                v-if="videohidden"
                ref="videoPlayer"
                :src="detailVideo"
                width="400px"
                class="imgsize"
                @ended="ended"
              ></video>
            <transition
              name="plus-icon"
              v-if="getLanguageData({ zh: detail.video, en: detail.video_en })"
            >
              <div class="block" v-if="slidevideo">
                <el-image
                  v-if="videoshow"
                  :src="img"
                  class="showvideo-image"
                  @click="beginvideo"
                ></el-image>
                <el-image
                  v-if="!videoshow"
                  :src="imgstop"
                  class="showvideo-image"
                  @click="stopvideo"
                ></el-image>
              </div>
            </transition>
             </div>
          </div>
          <div></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="button-place">
      <div class="swiper-button-prev"></div>
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div
            @mouseenter="enter(index)"
            class="swiper-slide"
            v-for="(item, index) in picture"
            :key="item.id"
          >
            <div class="imgcontainer" :class="{ active: index === current }">
              <el-image
                fit="contain"
                lazy
                class="image-price"
                :src="item.path"
                @mouseenter="checkimgswiper(index)"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import { getLanguageData } from "@/libs/tools.js";

import Swiper from "swiper";
import "./swiper-bundle.min.js";
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    picture: {},
    detail: {},
  },
  data() {
    return {
      current: 0,
      img: require("./swiperimg/videoimg.png"),
      imgstop: require("./swiperimg/videostop.png"),
      videoshow: true, //按钮切换隐藏
      slidevideo: false, //播放按钮
      myVideo: "",
      videohidden: false,//video
      video: {},
    };
  },
  mounted() {
    var swiper = new Swiper(".mySwiper", {
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      loop: true, // 循环模式选项
      slidesPerView: 3,
      spaceBetween: -10,
      freeMode: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  computed: {
    detailVideo() {
      return this.getLanguageData({
        zh: this.detail.video,
        en: this.detail.video_en,
      });
    },
    loading: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {
    detail(newvalue, oldvalue) {
      this.video = newvalue;
      if (this.detail.video || this.detail.video_en) {
        this.slidevideo = true;
        this.loading = false;
      } else {
        this.slidevideo = false;
        this.loading = false;
      }
    },
  },
  methods: {
    mouserentervideo() {
       if(this.videohidden){
           this.slidevideo = true;
      }
    
    },
    mouseleavevideo() {
      if(this.videohidden){
            this.slidevideo = false;
      }
    },
    changevideofalse(e) {
      if (e < 1) {
        this.slidevideo = true;
      } else {
        this.slidevideo = false;
      }
    },
    ended() {    
      this.videohidden = false;
     this.$nextTick(() => {
        this.videoshow=true, 
        this.slidevideo= true 
      });
    },
    getLanguageData,
    //进来
    enter(index) {
      this.videoshow = true;  
        this.videohidden = false;
        if(index<1){
       this.slidevideo=true
       }
    },
    //停止
    stopvideo() {
      this.videohidden = true;
      this.videoshow = true;
      this.$nextTick(() => {
        this.myVideo[0].pause();
      });
    },
    //播放
    beginvideo() {
      this.videohidden = true;
      this.videoshow = false;
      this.$nextTick(() => {
        this.myVideo = this.$refs.videoPlayer;
       let play= this.myVideo[0].play()
					if (play) {
						play.then(() => {
							setTimeout(() => {				
							}, this.myVideo[0].duration * 1000); 
						}).catch((e) => {
						})
					}

      });
    },
    //鼠标划上切换对应的图片
    checkimgswiper(index) {
      this.$refs.cardShow.setActiveItem(index);
      this.current = index;
    },
  },
};
</script>
<style  scoped>
.showvideo-image {
  z-index: 5;
  position: absolute;
  left: 179px;
  top: -156px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.imgcontainer {
  width: 88px;
  height: 60px;
  border: 1px solid #ededed;

}
.image-price {
  height: 99%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}
.swiperwidth {
  width: 400px;
  display: table;
  text-align: center;
}
.imgsize {
  height: 298px;
}
@import "./swiper-bundle.min.css";
.active {
  border: 2px solid #3053A3;
  z-index: 10;
  
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 317px;
  height: 100%;
  margin-right: 10px;
  margin-left: -19px;
  margin-top: 10px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 88px;
  margin-right: 0px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.plus-icon-enter-active {
  transition: opacity 0.5s;
}
.plus-icon-enter {
  opacity: 0;
}
.plus-icon-leave-active {
  transition: opacity 0.5s;
}
.plus-icon-leave-to {
  opacity: 0;
}
.block {
  position: relative;
}
.button-place {
  width: 300px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  margin-left: 60px;
}
.el-icon-arrow-left {
  top: 20px;
  right: 20px;
}
.swiper-button-prev {
  height: 47px;
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #303133; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 20px; /* 设置按钮大小 */
  margin-top: 130px;
  margin-left: 8px;
  outline: none;
}
.swiper-button-next {
  height: 43px;
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #303133; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 20px; /* 设置按钮大小 */
  margin-top: 132px;
  margin-right: 768px;
  outline: none;
}
.small {
  width: 100%;
  height: 300px;
}
.small:hover .block {
  background-color: red;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 16px;
}
</style>